import React from 'react';
import './SeriesUpdates.css';
import booksData from './booksData'; 

const SeriesUpdates = () => {
  const comingSoonBooks = booksData
    .flatMap(series => series.books)
    .filter(book => book.comingSoon)
    .sort((a, b) => a.comingSoon - b.comingSoon); 

  return (
    <div className="series-updates">
      <div className="book-covers-container">
        {comingSoonBooks.map((book, index) => (
          <div key={index} className="book-cover">
            <p className="book-release-date">{book.description}</p>
            <img src={book.cover} alt="Book Cover" className="cover-img" />
            <p className="book-long-description">{book.comingSoonDescription}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeriesUpdates;
