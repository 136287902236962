import './UpdateText.css';

import React, { useState, useEffect } from 'react';

const UpdateText = () => {
  const [updateText, setUpdateText] = useState('');

  useEffect(() => {
    fetch('/updates.json')
      .then(response => response.json())
      .then(data => setUpdateText(data.htmlContent))
      .catch(error => console.error('Error fetching update text:', error));
  }, []);

  return (
    <div className="update-text-container" dangerouslySetInnerHTML={{ __html: updateText }} />
  );
};

export default UpdateText;
